<template>
 <div>
        <v-row dense>
            <v-col cols="12" class="">
             <v-card class="ma-2">
              <v-toolbar dense flat class="pa-1">
                  <v-spacer/>
                   <v-spacer/>
                 <span style="width: 240px; margin-top: 24px;"> 
                     <v-menu
                        v-model="to_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                              label="Date"
                              :value="to_date"
                              readonly
                              filled
                              dense
                              outlined
                              append-icon="mdi-calendar"
                              v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="to_date" no-title scrollable @input="to_menu=false, setData()" />
                    </v-menu>
               </span>
              
              </v-toolbar>
              
              <div class="pa-2 font-weight-bold mt-4">COLLECTION SUMMARY</div>
                  <table class="va-table print_table3 mt-1 font-weight-bold text-left" cellspacing="0">
                     <tr>
                       <!-- <th  style="background: #eaeaea; padding: 4px">GAME</th> -->
                        <th  style="background: #eaeaea; padding: 4px">DRAW</th>
                       <th  style="background: #eaeaea; padding: 4px" class="text-right">COLLECTION</th>
                       <th  style="background: #eaeaea; padding: 4px" class="text-right">COMM</th>
                     </tr>
                      <tr v-for="(item, i) in $store.state.account_summary.gross" :key="i">
                          <!-- <td>
                            {{item.game}}
                          </td> -->
                          <td>
                            {{item.game}} - {{$moment(item.draw_date).format('hh:mm A')}}
                          </td>
                          <td class="text-right">
                            {{$money(item.gross)}}
                          </td>
                          <td class="text-right">
                            {{$money(commission(item.gross))}}
                          </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td class="lg-font ma-1 text-primary text-right">{{$money(TOTAL)}}</td>
                        <td  class="lg-font ma-1 text-primary text-right">{{$money(TOTALCOMMISSION)}}</td>
                      </tr>
                  </table>
                  
                    <!-- <div class="pa-2 font-weight-bold">WIN SUMMARY</div>
                  <table class="va-table print_table3 mt-1 font-weight-bold  text-left" cellspacing="0">
                     <tr>
                      
                        <th  style="background: #eaeaea; padding: 4px">Draw</th>
                        <th  style="background: #eaeaea; padding: 4px">UNITS</th>
                       <th  style="background: #eaeaea; padding: 4px" class="text-right">WIN</th>
                     </tr>
                      <tr v-for="(item, i) in $store.state.account_summary.wins" :key="i">
                         
                          
                          <td>
                            {{$moment(item.draw_date).format('hh:mm A')}}
                          </td>
                          <td>{{$NumPad(item.gross,2)}}</td>
                          <td class="text-right">
                            {{$money($IsNum(item.payout) - less($IsNum(item.payout)))}}
                          </td>
                      </tr>
                  </table>
                   <v-col cols="12" class="text-right">
                        <span class="lg-font ma-1 text-primary"> WIN: {{$money(WIN)}}</span>
                    </v-col> -->
                  
             </v-card>
            </v-col>
            <v-col cols="12">
              <table class="va-table print_table3 mt-1  text-left" cellspacing="0">
                     <tr>
                       <th  style="background: #eaeaea; padding: 4px" colspan="2">PAYOUTS</th>
                     </tr>
                      <tr v-for="(item, i) in $store.state.account_summary.wins" :key="i">
                         
                          <td class="text-left ">
                            <strong>{{item.game}} - {{$moment(item.draw_date).format('hh:mm A')}} </strong>
                            <br/>COMM:{{$money(commission(item.gross))}}
                           <br/> WINS: {{$money(less(item.amount_of_winning))}}
                           <br/><strong>TOTAL:  {{$money(less(item.amount_of_winning) + commission(item.gross))}}</strong>
                          </td>
                        
                         
                          <td class="text-center">
                            <v-btn color="info" :loading="loading" tile small v-if="item.status=='Drawn' && item.payout=='available'" @click="requestPayout(item)">Request Payout</v-btn>
                            <span v-else :class="STATUS_COLOR(item.payout)">{{item.payout}}</span>
                          </td>
                      </tr>
                      <tr>
                       
                        <td  class="lg-font ma-1 text-primary text-left" colspan="2">TOTAL: {{$money(TOTALWIN + TOTALCOMMISSION)}}</td>
                      </tr>
                  </table> 
            </v-col>
        </v-row>
        <va-cashout-form :show="confirm" :cashout="cashout" @DialogEvent="cfEv"/>
  </div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
      cashout: {},
      to_menu: false,
      viewticket: false,
      to_date:'',
      loading: false,
      confirm: false,
      cancelbtn: 'No',
      okbtn: "Ok",
      confirmText: "",
      action:'',
      game:'All Games'
    }),
    created() {
      this.PING()
    },
     mounted(){
       this.setLoggedIn(true)
        if (this.user == null) {
          this.$router.push('/')
        }else {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
          this.to_date = this.$moment().format("YYYY-MM-DD")
          this.setData()
        }
    },
    computed:{
      user() {
        if(localStorage.user){
            return JSON.parse(localStorage.user)
        }
        return null
      },
      TOTALCOMMISSION(){
        return this.commission(this.TOTAL)
      },
      TOTAL(){
        if(this.$store.state.account_summary.gross == null) {
          return 0
        }
        var total =  this.$store.state.account_summary.gross.reduce((res, item)=>res += this.$IsNum(item.gross),0)
        return total
      },
      TOTALWIN() {
        if(this.$store.state.account_summary.wins == null) {
          return 0
        }
        var total =  this.$store.state.account_summary.wins.reduce((res, item)=>{
            res = res+ this.$IsNum(item.amount_of_winning) 
          return res
        },0)
        return this.less(total)

      },
       WIN(){
        if(this.$store.state.account_summary.wins == null) {
          return 0
        }
        var total =  this.$store.state.account_summary.wins.reduce((res, item)=>{
          res = res+ this.$IsNum(item.payout) * this.$IsNum(item.gross)
          return res
        },0)
        return total - this.less(total)
      },
      isOnline(){
        return this.$store.state.is_online
      }
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      cfEv(){
        this.confirm = false
        this.setData()
      },
      requestPayout(item){
        let params = {
          user_id: this.$IsNum(this.user.id),
          amount: this.$IsNum(this.less(item.amount_of_winning) + this.commission(item.gross)),
          total: this.$IsNum(this.less(item.amount_of_winning) + this.commission(item.gross)),
          fee:0,
          draw_id: this.$IsNum(item.draw_id),
          items: {
            game: item.game,
            draw_date: item.draw_date,
            gross: item.gross,
            commission: this.commission(item.gross),
            wins: this.less(item.amount_of_winning),
          }
        }
        this.cashout = params
        this.confirm=true

        console.log(params)
      },
      less(amount){
        let lessPercent =this.$store.state.account_summary.less
        return amount - (amount * (this.$IsNum(lessPercent)/100))
      },
      ticketEvent(){
        this.viewticket =false
      },
      commission(collection) {
          let agent_comm = this.$IsNum(this.user.commission)
          return collection * (agent_comm/100);
      },
      setData() {
        var param = {}
        param.account = this.user.id
       // param.method = "per_account"
        param.from_date = this.to_date
        param.to_date = this.to_date
        param.method = "single_account_gross_per_draw"
        this.ACCOUNT_SUMMARY(param)
      }
    },
  }
</script>